import { useLazyQuery } from '@apollo/client'
import { Box, Tooltip, Typography } from '@mui/material'
import { UserMessage } from '@sendbird/chat/message'
import { useChannelContext } from '@sendbird/uikit-react/Channel/context'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { graphql } from 'src/gql'
import { selectUserId } from 'src/store/selectors/auth'
import { useAppSelector } from 'src/store/useAppSelector'

import { MessageOptions } from './MessageOptions'
import { useReadReceipts } from './useReadReceipts'

interface Props {
  message: UserMessage
}

export const Message = ({ message }: Props) => {
  const { allMessages } = useChannelContext()
  const userId = useAppSelector(selectUserId)
  const { lastMemberRead, lastReadMessageId } = useReadReceipts()
  const [hover, setHover] = useState(false)
  const [getPreferredName, { data }] = useLazyQuery(
    graphql(`
      query MemberPreferredName($id: Int!) {
        findMember(memberId: $id) {
          id
          preferredName
        }
      }
    `),
  )
  const messagesDays = useMemo(() => {
    const timestamps: Record<string, number[]> = {}

    allMessages.forEach(message => {
      const startOfDay = DateTime.fromMillis(message.createdAt)
        .startOf('day')
        .toISO()
      timestamps[startOfDay] = timestamps[startOfDay] || []
      timestamps[startOfDay].push(message.createdAt)
      timestamps[startOfDay].sort()
    })

    return timestamps
  }, [allMessages])

  const messageIdx = allMessages.findIndex(
    ({ messageId }) => messageId === message.messageId,
  )
  let previousMessage: UserMessage | undefined
  if (allMessages[messageIdx - 1]?.messageType === 'user')
    previousMessage = allMessages[messageIdx - 1] as UserMessage
  const previousMessageSameSender =
    !!previousMessage && message.sender.userId === previousMessage.sender.userId
  const newDay = DateTime.fromMillis(message.createdAt).startOf('day').toISO()
  const timestamps = messagesDays[newDay] || []
  const isFirstOfDay = message.createdAt === timestamps[0]

  const formattedSendDate = DateTime.fromMillis(message.createdAt)
    .toFormat('h:mm a')
    .toUpperCase()

  const isSelf = message.sender.userId === `dashboard_${userId}`
  const isMember = message.sender.userId.includes('app_')
  const messageRead =
    lastMemberRead && !isMember && message.createdAt <= lastMemberRead
  const lastReadMessage = messageRead && message.messageId === lastReadMessageId
  const senderId = message.sender.userId

  useEffect(() => {
    if (!isMember) return
    getPreferredName({ variables: { id: +senderId.split('_')[1] } })
  }, [isMember, senderId, getPreferredName])

  return (
    <Box
      sx={t => ({
        paddingTop: t.spacing(2),
        flexDirection: 'column',
        maxWidth: '100%',
        display: 'flex',
        alignSelf: isSelf ? 'flex-end' : 'flex-start',
        justifyContent: isSelf ? 'flex-end' : 'flex-start',
        marginRight: t.spacing(2),
      })}>
      {(!previousMessageSameSender || isFirstOfDay) && (
        <Box
          ml={t => t.spacing(2)}
          mr={t => t.spacing(1)}
          alignSelf={isSelf ? 'flex-end' : 'flex-start'}
          display="flex">
          {!isSelf && (
            <Typography
              variant="caption"
              color="textPrimary"
              style={{ fontWeight: 500 }}>
              {data?.findMember?.preferredName ?? message.sender.nickname}
            </Typography>
          )}
          <Typography
            ml={t => t.spacing(1)}
            variant="caption"
            color="textSecondary">
            {formattedSendDate}
          </Typography>
        </Box>
      )}
      <Box
        maxWidth="85%"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        display="flex"
        flexDirection="row"
        alignSelf={isSelf ? 'flex-end' : 'flex-start'}>
        <Box display="flex" flexDirection="column">
          <Tooltip
            arrow
            placement="top"
            title={DateTime.fromMillis(message.createdAt).toFormat('h:mm a')}>
            <Box display="flex" flexDirection="row" alignItems="center">
              {isSelf && hover && <MessageOptions message={message} />}
              <Box
                p={t => t.spacing(2, 3)}
                whiteSpace="pre-wrap"
                borderRadius={5}
                bgcolor={isSelf ? 'rgb(55, 168, 179)' : 'rgb(216, 228, 223)'}>
                <Typography
                  display="block"
                  variant="body2"
                  style={{
                    flexDirection: 'row',
                    color: isSelf ? 'white' : 'inherit',
                  }}>
                  {message.message}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
          {lastReadMessage && (
            <Box alignSelf="end">
              <Typography
                ml={t => t.spacing(1)}
                color="textSecondary"
                variant="caption">
                {'Read'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
